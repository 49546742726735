
import * as Components from "./Omars"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "omars"
}

